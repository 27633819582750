import React from 'react'
import { NavLink } from 'react-router-dom'
import PhenobleLogo  from '../assets/images/Phenoble2.png'
import classes from './FooterNavigation.module.css'


const FooterNavigation = () => {

    let year = new Date().getFullYear();

  return (

    <div className="container-fluid mt-5 " style={{backgroundColor: "#006eff", minHeight:"40vh"}}>
        <div className="row align-items-start pt-5">
            <div className="col-sm-12 col-md-2 col-lg-2 pb-3">
                <NavLink to='/'>
                    <img src={PhenobleLogo} alt='header-logo' className={classes.footerlogo} />
                </NavLink>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-2">
                <ul style={{listStyle:"none", color:"white", textAlign:"justify"}}>
                    <li>
                        <h3>Company</h3>
                    </li>
                    <li>
                        <NavLink to='/' className="nav-link">Home</NavLink>
                    </li>
                    <li>
                        <NavLink to='/services' className="nav-link">Services</NavLink>
                    </li>
                    <li>
                        <NavLink to='/about' className="nav-link">About</NavLink>
                    </li>
                    <li>
                        <NavLink to='/career' className="nav-link">Career</NavLink>
                    </li>
                    <li>
                        <NavLink to='/blog' className="nav-link">Blog</NavLink>
                    </li>
                    {/* <li>
                        <NavLink to='/victoryvault' className="nav-link">Victory Vault</NavLink>
                    </li> */}
                    <li>
                        <NavLink to='/contact' className="nav-link">Contact</NavLink>
                    </li>
                </ul>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-2">
                <ul style={{listStyle:"none", color:"white", textAlign:"justify"}}>
                    <li>
                        <h3>Follow Us</h3>
                    </li>
                    <li style={{listStyle:"none", margin:"0 0 10px", color:"white"}}>
                        <NavLink to='https://twitter.com/' className="nav-link" target='blank'>Twitter</NavLink>
                    </li>
                    <li style={{listStyle:"none", margin:"0 0 10px", color:"white"}}>
                        <NavLink to='https://www.linkedin.com/company/phenoble-software/' className="nav-link"
                         target='blank'>LinkedIn</NavLink>
                    </li>
                    <li style={{listStyle:"none", margin:"0 0 10px", color:"white"}}>
                        <NavLink to='https://www.instagram.com/' className="nav-link" target='blank'>Instagram</NavLink>
                    </li>
                </ul>
            </div>

            <div className="col-sm-12 col-md-3 col-lg-3">
                <ul style={{listStyle:"none", color:"white", textAlign:"justify"}}>
                    <li>
                        <h3>Connect with us</h3>
                    </li>
                    <li>
                        <p>sales@phenoble.com</p>
                    </li>
                    <li>
                        <p>hr@phenoble.com</p>
                    </li>
                    <li>
                        <p>+91 7850952954</p>
                    </li>
                </ul>
            </div>
        </div>
        <hr style={{color:"white"}}></hr>
        <div style={{minHeight: "8vh"}}>
            <div className="col">
                <p style={{color:"white"}}>&#169; {year} Phenoble Software Private Limited</p>
            </div>
        </div>
    </div>
  )
}

export default FooterNavigation