import { createSlice } from "@reduxjs/toolkit";

import image1 from "../assets/blogimages/security-level-permission-in-salesforces.webp"
import image2 from "../assets/blogimages/life-cycle-hooks-in-ligtning-web-component.webp"
import image3 from "../assets/blogimages/how-to-email-to-case-in-salesforce.webp"
import image4 from "../assets/blogimages/life-cycle-hooks-in-aura-components.webp"
import image5 from "../assets/blogimages/communication-between-lightning-web-components-in-salesforce.webp"
import image6 from "../assets/blogimages/web-to-lead-in-salesforce.webp"
import image7 from "../assets/blogimages/web-to-case-in-salesforce.webp"
import image8 from "../assets/blogimages/email-to-lead-in-salesforce.webp"
import image9 from "../assets/blogimages/record-trigger-flow.webp"
import image10 from "../assets/blogimages/salesforce-cpq-how-to-setup-salesforce-cpq.webp"
import image11 from "../assets/blogimages/salesforce-cpq-bundle-product.webp"
import image12 from "../assets/blogimages/salesforce-cpq-option-constraints.webp"
import image13 from "../assets/blogimages/salesforce-cpq-configuration-attribute.webp"
import image14 from "../assets/blogimages/salesforce-cpq-product-rule.webp"
import image15 from "../assets/blogimages/salesforce-cpq-subscription-product-and-price-dimensions-(multidimensional-quote).webp"
import image16 from "../assets/blogimages/salesforce-cpq-block-pricing.webp"
import image17 from "../assets/blogimages/salesforce-cpq-cost-and-markup-pricing.webp"
import image18 from "../assets/blogimages/salesforce-CPQ-percent-of-total-pricing.webp"
import image19 from "../assets/blogimages/salesforce-CPQ-account-based-contract-pricing.webp"
import image20 from "../assets/blogimages/salesforce-CPQ-guided-selling.webp"
import image21 from "../assets/blogimages/how-to-configure-first-experience-site-in-salesforce.webp"
import image22 from "../assets/blogimages/salesforce-CPQ-custom-actions.webp"
import image23 from "../assets/blogimages/salesforce-CPQ-summary-variable.webp"
import image24 from "../assets/blogimages/passing-wrapper-from-apex-to-flow-in-salesforce.webp"
import image25 from "../assets/blogimages/append-edit-and-delete-button-inside-dropdown-menu-of-actions-to-a-column-in-lightning-datatable.webp"
import image26 from "../assets/blogimages/lightning-tree-grid-table-inside-lightning-web-component.webp"
import image27 from "../assets/blogimages/how-to-secure-your-salesforce-queries-with-String-escapeSingleQuotes.webp"
import image28 from "../assets/blogimages/understanding-salesforce-strip-inaccessible-for-security-and-permissions-management.webp"
import image29 from "../assets/blogimages/how-to-integrate-salesforce-with-teamup.webp"

const BLOGDATA = {
    "salesforce": [
        {
            "id": 1,
            "slug":"security-level-permission-in-salesforces",
            "path":"blogs/security-level-permission-in-salesforces.html",
            "title": "Security Level Permission In Salesforces",
            "date":"Sept 26, 2023",
            "description":"Sharing settings typically refer to the configurable options that control how information or resources are shared within a digital platform, application, or system. These settings determine who can access, view, modify, or interact with specific content, data, or functionalities.",
            "image":image1, 
        },
        {
            "id": 2,
            "slug":"life-cycle-hooks-in-ligtning-web-component",
            "path":"blogs/life-cycle-hooks-in-ligtning-web-component.html",
            "title": "Life cycle hooks in lightning web component",
            "date": "Sept 27, 2023",
            "description":"This lifecycle hook called when component in created",
            "image":image2,
        },
        {
            "id": 3,
            "slug":"how-to-email-to-case-in-salesforce",
            "path":"blogs/how-to-email-to-case-in-salesforce.html",
            "title": "How to email to case in salesforce",
            "date": "Sept 30, 2023",
            "description":"Email-to-Case is a feature in Salesforce that allows you to automatically create or update cases in Salesforce Service Cloud from inbound emails.",
            "image":image3,
        },
        {
            "id": 4,
            "slug":"Life cycle hooks in aura components",
            "path":"blogs/life-cycle-hooks-in-aura-components.html",
            "title": "Life cycle hooks in aura components",
            "date": "Oct 02, 2023",
            "description":"A component is instantiated, rendered, and rerendered during its lifecycle. A component rerenders only when there&rsquo;s a programmatic or value change that requires a rerender. For example, if a browser event triggers an action that updates the component&rsquo;s data, the component rerenders. ",
            "image":image4,
        },
        {
            "id": 5,
            "slug":"Communication between lightning web components in salesforce",
            "path":"blogs/communication-between-lightning-web-components-in-salesforce.html",
            "title": "Communication between lightning web components in salesforce",
            "date": "Oct 14, 2023",
            "description":"A component is instantiated, rendered, and rerendered during its lifecycle. A component rerenders only when there&rsquo;s a programmatic or value change that requires a rerender. For example, if a browser event triggers an action that updates the component&rsquo;s data, the component rerenders. ",
            "image":image5,
        },
        {
            "id": 6,
            "slug":"web-to-lead-in-salesforce",
            "path":"blogs/web-to-lead-in-salesforce.html",
            "title": "Web to Lead in Salesforce",
            "date": "Oct 23, 2023",
            "description":"In the dynamic realm of customer relationship management (CRM), effective lead generation is the cornerstone of business success. Salesforce, a leader in CRM solutions,",
            "image":image6,
        },
        {
            "id": 7,
            "slug":"web-to-case-in-salesforce",
            "path":"blogs/web-to-case-in-salesforce.html",
            "title": "Web to Case in Salesforce",
            "date": "Oct 23, 2023",
            "description":"In today's fast-paced digital landscape, delivering exceptional customer service is not just a goal; it's a necessity. As businesses strive to meet the ever-growing demands of their customers",
            "image":image7,
        },
        {
            "id": 8,
            "slug":"email-to-lead-in-salesforce",
            "path":"blogs/email-to-lead-in-salesforce.html",
            "title":"Email to Lead in Salesforce",
            "date": "Nov 09, 2023",
            "description":" Email-to-Lead is a feature in Salesforce that enables businesses to automatically convert incoming emails into potential sales leads. ",
            "image":image8,
        },
        {
            "id": 9,
            "slug":"record-trigger-flow",
            "path":"blogs/record-trigger-flow.html",
            "title": "Record trigger flow",
            "date": "Dec 15, 2023",
            "description":"As businesses evolve and user expectations soar, the demand for robust automation solutions has never been higher. Enter Record-Triggered Flows",
            "image":image9,
        },
        {
            "id": 10,
            "slug":"salesforce-cpq-how-to-setup-salesforce-cpq",
            "path":"blogs/salesforce-cpq-how-to-setup-salesforce-cpq.html",
            "title": "Salesforce CPQ : How to Setup Salesforce CPQ",
            "date": "Dec 16, 2023",
            "description":"CPQ stands for Configure, Price, Quote. It is a business software that helps businesses to automate the process of configuring, pricing, and quoting",
            "image":image10,
        },
        {
            "id": 11,
            "slug":"salesforce-cpq-bundle-product",
            "path":"blogs/salesforce-cpq-bundle-product.html",
            "title": "Salesforce CPQ : Bundle Product",
            "date": "Dec 16, 2023",
            "description":"A bundle product is a grouping of multiple individual products or services that are sold together as a single package.",
            "image":image11,
        },
        {
            "id": 12,
            "slug":"salesforce-cpq-option-constraints",
            "path":"blogs/salesforce-cpq-option-constraints.html",
            "title": "Salesforce CPQ: Option Constraints",
            "date": "Dec 16, 2023",
            "description":"Option constraints in Salesforce CPQ are a powerful feature that allows you to control which product options are available for selection within a bundle product.",
            "image":image12,
        },
        {
            "id": 13,
            "slug":"salesforce-cpq-configuration-attribute",
            "path":"blogs/salesforce-cpq-configuration-attribute.html",
            "title": "Salesforce CPQ: Configuration Attribute",
            "date": "Dec 16, 2023",
            "description":"A configuration attribute in Salesforce CPQ is a picklist field that allows you to define specific characteristics or options for a product or bundle.",
            "image":image13,
        },
        {
            "id": 14,
            "slug":"salesforce-cpq-product-rule",
            "path":"blogs/salesforce-cpq-product-rule.html",
            "title": "Salesforce CPQ: Product Rule",
            "date": "Dec 16, 2023",
            "description":"A Product Rule in Salesforce CPQ is a powerful tool that allows you to automate business logic and guide sales reps during the product configuration process.",
            "image":image14,
        },
        {
            "id": 15,
            "slug":"salesforce-cpq-subscription-product-and-price-dimensions-(multidimensional-quote)",
            "path":"blogs/salesforce-cpq-subscription-product-and-price-dimensions-(multidimensional-quote).html",
            "title": "Salesforce CPQ: Subscription Product and Price Dimensions (Multidimensional Quote)",
            "date": "Dec 16, 2023",
            "description":"In Salesforce CPQ (Configure, Price, Quote), a subscription product refers to a type of product or service that is sold on a recurring basis.",
            "image":image15,
        },
        {
            "id": 16,
            "slug":"salesforce-cpq-block-pricing",
            "path":"blogs/salesforce-cpq-block-pricing.html",
            "title": "Salesforce CPQ: Block Pricing",
            "date": "Dec 16, 2023",
            "description":"Block pricing is used for managing product pricing based on quantity. It allows you to define different price points for specific quantity ranges",
            "image":image16,
        },
        {
            "id": 17,
            "slug":"salesforce-cpq-cost-and-markup-pricing",
            "path":"blogs/salesforce-cpq-cost-and-markup-pricing.html",
            "title": "Salesforce CPQ: Cost and Markup Pricing",
            "date": "Dec 16, 2023",
            "description":"Cost pricing is a method of setting product prices based on their cost plus a desired markup. This means that instead of using",
            "image":image17,
        },
        {
            "id": 18,
            "slug":"salesforce-CPQ-percent-of-total-pricing",
            "path":"blogs/salesforce-CPQ-percent-of-total-pricing.html",
            "title": "Salesforce CPQ: Percent of Total Pricing",
            "date": "Dec 25, 2023",
            "description":"In Salesforce CPQ, Percent of Total pricing is a feature that allows you to set a product's list price as a percentage of the",
            "image":image18,
        },
        {
            "id": 19,
            "slug":"salesforce-CPQ-account-based-contract-pricing",
            "path":"blogs/salesforce-CPQ-account-based-contract-pricing.html",
            "title": "Salesforce CPQ: Account Based Contract Pricing",
            "date": "Dec 25, 2023",
            "description":"In Salesforce CPQ, account based contract pricing allows you to negotiate and apply custom prices for products and services",
            "image":image19,
        },
        {
            "id": 20,
            "slug":"salesforce-CPQ-guided-selling",
            "path":"blogs/salesforce-CPQ-guided-selling.html",
            "title": "Salesforce CPQ: Guided Selling",
            "date": "Dec 27, 2023",
            "description":"Guided selling in Salesforce CPQ is a feature that helps sales reps quickly and easily find the right products for their",
            "image":image20,
        },
        {
            "id": 21,
            "slug":"how-to-configure-first-experience-site-in-salesforce",
            "path":"blogs/how-to-configure-first-experience-site-in-salesforce.html",
            "title": "How to configure first experience site in salesforce",
            "date": "April 22, 2024",
            "description":"Salesforce Experience Cloud use to create branded digital experience to share information and collaborate with people who are key to your business process",
            "image":image21,
        },
        {
            "id": 22,
            "slug":"salesforce-CPQ-custom-actions",
            "path":"blogs/salesforce-CPQ-custom-actions.html",
            "title": "Salesforce CPQ: Custom Actions",
            "date": "May 6, 2024",
            "description":"Custom actions are buttons that sales representatives can utilize to perform specific actions within the CPQ interface.",
            "image":image22,
        },
        {
            "id": 23,
            "slug":"salesforce-CPQ-summary-variable",
            "path":"blogs/salesforce-CPQ-summary-variable.html",
            "title": "Salesforce CPQ: Summary Variable",
            "date": "May 6, 2024",
            "description":"Summary Variable allows you to perform mathematical operations on collected values from a number of fields associated with various records",
            "image":image23,
        },
        {
            "id": 24,
            "slug":"passing-wrapper-from-apex-to-flow-in-salesforce",
            "path":"blogs/passing-wrapper-from-apex-to-flow-in-salesforce.html",
            "title": "Passing Wrapper from Apex to Flow in Salesforce",
            "date": "July 8, 2024",
            "description":"In Salesforce development, integrating Apex with Flows allows for powerful automation and customization.",
            "image":image24,
        },
        {
            "id": 25,
            "slug":"append-edit-and-delete-button-inside-dropdown-menu-of-actions-to-a-column-in-lightning-datatable",
            "path":"blogs/append-edit-and-delete-button-inside-dropdown-menu-of-actions-to-a-column-in-lightning-datatable.html",
            "title": "Append Edit and Delete button inside dropdown menu of actions to a column in lightning datatable",
            "date": "Sept 27, 2024",
            "description":"To add Edit and Delete button for operation we have to create",
            "image":image25,
        },
        {
            "id": 26,
            "slug":"lightning-tree-grid-table-inside-lightning-web-component",
            "path":"blogs/lightning-tree-grid-table-inside-lightning-web-component.html",
            "title": "Lightning tree grid table inside lightning web component",
            "date": "Nov 15, 2024",
            "description":"A lightning-tree-grid is a table that shows data in a hierarchy",
            "image":image26,
        },
        {
            "id": 27,
            "slug":"how-to-secure-your-salesforce-queries-with-String-escapeSingleQuotes",
            "path":"blogs/how-to-secure-your-salesforce-queries-with-String-escapeSingleQuotes.html",
            "title": "How to Secure Your Salesforce Queries with String.escapeSingleQuotes",
            "date": "Nov 22, 2024",
            "description":"In Salesforce development, writing secure and efficient code is",
            "image":image27,
        },
        {
            "id": 28,
            "slug":"understanding-salesforce-strip-inaccessible-for-security-and-permissions-management",
            "path":"blogs/understanding-salesforce-strip-inaccessible-for-security-and-permissions-management.html",
            "title": "Understanding salesforce strip inaccessible for security and permissions management",
            "date": "Nov 23, 2024",
            "description":"Salesforce provides a powerful toolset for developers to manage and enforce",
            "image":image28,
        },
        {
            "id": 29,
            "slug":"how-to-integrate-salesforce-with-teamup",
            "path":"blogs/how-to-integrate-salesforce-with-teamup.html",
            "title": "How to integrate salesforce with teamup",
            "date": "Jan 13, 2025",
            "description":"In this blog, we will learn how to integrate Salesforce with Teamup. ",
            "image":image29,
        }

    ]
}

BLOGDATA.salesforce.sort((a, b) => 
    b.id - a.id
)

const blogSlice = createSlice({
    name:"blog",
    initialState : {data: BLOGDATA}, 
    
})

export const blogActions = blogSlice.actions

export default blogSlice;